import * as React from 'react'
import Box, { SeparatorBox } from '../components/generic/Box';

import ModFindInput from '../components/generic/ModFindInput';
import { t } from 'i18next';
import ModFindTextarea from '../components/generic/ModFindTextArea';
import Switch from "react-switch";
import { Colors } from '../styles/colors';
import Button from '../components/generic/Button';
import { Brand, Category, SubCategory, Post, PostsAPI, PostStatus, PostItemAttribute, User, UsersAPI, APIError, PostDraft } from 'fullcircle-api';
import ModFindImageGrid, { ModFindImage } from '../components/grid/ModFindImageGrid';
import { ModFindCurrencyInput } from '../components/generic/ModFindCurrencyInput';
import { RemoteFile, RemoteImage, isRemoteFile, errorToString, hasShipping, includesEmail, includesPhoneNumber } from '../utils/helper';
import { RouteComponentProps, withRouter, Route, Switch as RouterSwitch } from 'react-router';
import { BrandDropDown } from '../components/dropdown/BrandDropDown';
import { CategoryDropDown } from '../components/dropdown/CategoryDropDown';
import { ModelTypeDropDown } from '../components/dropdown/ModelTypeDropDown';
import { LocationDetails, loadLocationForLatLng } from '../utils/location';
import { SubCategoryDropDown } from '../components/dropdown/SubCategoryDropDown';
import { unformatPriceI18n, formatPriceI18n } from '../utils/formater';
import ModFindPopUp, { TitleMessageContent } from '../components/popup/ModFindPopUp';
import { Popup } from 'semantic-ui-react';
import ModFindShare from '../components/views/ModFindShare';
import { getPostUrl } from '../utils/path';
import ClipLoader from 'react-spinners/ClipLoader'
import { StaticSourceDropDown } from '../components/dropdown/StaticSourceDropDown';
import ModFindToast from '../components/generic/ModFindToast';
import { UserContextType } from '../context/user';
import { UserSession } from '../utils/session';
import { DeliveryMethod, PostType, Variant, VariantOption, Variants } from 'fullcircle-api/lib/models/post';
import _ from 'lodash';
import { YearDropDown } from '../components/dropdown/YearDropDown';
import { ZipCodeInput } from '../components/generic/ZipCodeInput';
import ZipCodeRequiredPopup from '../components/popup/ZipCodeRequiredPopup';
import { compareVariantArray, getKeyForProperty, getPropertiesForCarsForSale } from '../utils/post_util';
import * as toastr from 'toastr'
import { IC_CHECK_BLUE, IC_MINUS_BORDER, IC_PLUS_BORDER } from '../assets';
import { FeaturedCheckout } from '../components/FeaturedCheckout';
import { ShippingRate } from 'fullcircle-api/lib/endpoints/posts';
import { lengthUnit, metricToSystemLength, metricToSystemWeight, systemToMetricLength, systemToMetricWeight, weightUnit } from '../utils/metric';
import { ModFindLoader } from '../components/generic/ModFindLoader';
import { EditVariant } from '../components/post/edit-variant';
import * as uuid from "uuid";
import { Alert } from '../components/Alert';

interface CreatePost {
    title?: string;
    description?: string;
    location?: LocationDetails;
    categories?: string[];
    subCategory?: string;
    brands?: string[];
    cars?: string[];
    images?: (File | RemoteFile)[];
    price?: number;
    kickback_price?: number;
    shipping_price?: number;
    delivery_method: DeliveryMethod;
    id?: string;
    order?: string[];
    status?: PostStatus;
    type: PostType;
    quantity: number
    item_attributes?: {
        [key: string]: PostItemAttribute;
    };
    variants?: Variants
}

interface SellingProps extends RouteComponentProps {
    post?: Post
    draft?: PostDraft
}

interface SellingState {
    will_ship: boolean;
    will_pickup: boolean;
    currency: string;
    category?: Category;
    subcategory?: SubCategory;
    images: (ModFindImage | RemoteImage)[];
    selectedBrands: Brand[];
    selectedYears: string[]
    selectedCars: string[]; // use ids here for performace reasons
    price: string;
    kickback_price: string;
    shipping_price: string;
    title: string;
    description: string;
    type: PostType;
    quantity: number;
    item_attributes?: {
        [key: string]: PostItemAttribute;
    };
    variants?: Variants;
    enableVariants: boolean;
    valid: boolean;
    posting: boolean;
    postingError?: string;
    apiError?: string;
    status: PostStatus
    createdPost?: {
        post: Post;
        link?: string;
        updated: boolean;
    };
    deletePost?: boolean
    deletePostLoading: boolean,
    phoneNumber: string,
    delivery_method: DeliveryMethod,
    location?: LocationDetails
    defaultZipOrCity?: string

    showZipRequiredPopup?: boolean

    featured_item: boolean

    calculate_shipping_price_at_checkout: boolean
    shipping_package_height?: string
    shipping_package_width?: string
    shipping_package_length?: string
    shipping_package_weight?: string
    shipping_rate_id?: string

    shipping_rates?: ShippingRate[]
    loading_shipping_rates: boolean
}

class Selling extends React.Component<SellingProps, SellingState> {

    static contextType = UserContextType
    context: User | undefined

    get isCarsForSale() {
        return this.state.category && this.state.category.name.toLowerCase() == 'Cars for Sale'.toLowerCase()
    }

    constructor(props: SellingProps) {
        super(props)
        let initialState: SellingState = {
            will_ship: true,
            will_pickup: false,
            images: [],
            selectedCars: [],
            selectedBrands: [],
            selectedYears: [],
            title: '',
            description: '',
            price: '',
            kickback_price: '',
            shipping_price: '',
            currency: this.context?.currency || 'usd',
            valid: false,
            posting: false,
            status: PostStatus.Public,
            deletePostLoading: false,
            phoneNumber: this.context && this.context?.phone_number || '',
            delivery_method: DeliveryMethod.SHIP,
            type: PostType.Single,
            quantity: 1,
            featured_item: false,
            calculate_shipping_price_at_checkout: false,
            loading_shipping_rates: false,
            enableVariants: false,
            variants: {
                variants: [{ id: uuid.v1(), name: '', options: [], required: false }],
                pricing: [],
                disabled: [],
                quantities: []
            }
        }

        if (props.post) {
            const post = props.post
            const location = this.getLocationObjectFromPost(post)
            initialState.category = post.categories[0]
            initialState.subcategory = post.sub_category
            initialState.images = this.getImagesFromPost(post)
            initialState.selectedBrands = post.brands
            initialState.selectedYears = post.cars.map(c => c.model_year!).unique()
            initialState.selectedCars = post.cars.map(c => c.id)
            initialState.title = post.title
            initialState.description = post.description_text
            initialState.price = formatPriceI18n(post.price, post.currency)
            initialState.kickback_price = post.kickback_price ? formatPriceI18n(post.kickback_price, post.currency) : ''
            initialState.shipping_price = post.shipping_price ? formatPriceI18n(post.shipping_price, post.currency) : ''
            initialState.will_ship = hasShipping(post) ? true : false
            initialState.status = post.status
            initialState.will_pickup = post.delivery_method !== DeliveryMethod.SHIP
            initialState.will_ship = post.delivery_method !== DeliveryMethod.PICKUP
            initialState.currency = post.currency
            initialState.location = location
            initialState.type = post.type || PostType.Single
            initialState.quantity = post.quantity
            initialState.location = location
            initialState.valid = true
            initialState.calculate_shipping_price_at_checkout = post.calculate_shipping_price_at_checkout
            initialState.shipping_package_height = metricToSystemLength(post.shipping_package_height)
            initialState.shipping_package_width = metricToSystemLength(post.shipping_package_width)
            initialState.shipping_package_length = metricToSystemLength(post.shipping_package_length)
            initialState.shipping_package_weight = metricToSystemWeight(post.shipping_package_weight)
            initialState.shipping_rate_id = post.shipping_rate_id
            initialState.variants = post.variants || initialState.variants;
            initialState.item_attributes = post.item_attributes;
            initialState.enableVariants = Boolean(post.variants?.variants?.length)
        } else if (props.draft) {
            const post = props.draft
            initialState = {
                ...initialState,
                location: this.getLocationObjectFromPost(post.post_attributes),
                category: post.post_attributes?.categories ? post.post_attributes.categories[0] : undefined,
                subcategory: post.post_attributes?.sub_category,
                images: this.getImagesFromPost(post.post_attributes) || [],
                selectedBrands: post.post_attributes?.brands || [],
                selectedCars: post.post_attributes?.cars?.map(m => m.id) || [],
                selectedYears: (post.post_attributes?.cars || []).map(t => t.model_year!).unique(),
                title: post.title,
                description: post.post_attributes?.description_text || '',
                price: post.post_attributes?.price && (post.post_attributes?.price as unknown as number) > 0 ? formatPriceI18n(post.post_attributes.price!, post.post_attributes.currency!) : '',
                kickback_price: post.post_attributes?.kickback_price && (post.post_attributes?.kickback_price as unknown as number) > 0 ? formatPriceI18n(post.post_attributes.kickback_price, post.post_attributes.currency!) : '',
                shipping_price: post.post_attributes?.shipping_price && (post.post_attributes?.shipping_price as unknown as number) > 0 ? formatPriceI18n(post.post_attributes.shipping_price, post.post_attributes.currency!) : '',
                delivery_method: post.post_attributes?.delivery_method || this.state.delivery_method,
                item_attributes: post.post_attributes?.item_attributes,
                quantity: post.post_attributes?.quantity || 1,
                type: post.post_attributes?.type || PostType.Single,
                calculate_shipping_price_at_checkout: !!post.post_attributes?.calculate_shipping_price_at_checkout,
                shipping_package_height: metricToSystemLength(post.post_attributes?.shipping_package_height),
                shipping_package_width: metricToSystemLength(post.post_attributes?.shipping_package_width),
                shipping_package_length: metricToSystemLength(post.post_attributes?.shipping_package_length),
                shipping_package_weight: metricToSystemWeight(post.post_attributes?.shipping_package_weight),
                shipping_rate_id: post.post_attributes?.shipping_rate_id,
                variants: post.post_attributes.variants || initialState.variants,
                enableVariants: Boolean(post.post_attributes.variants?.variants.length)
            }
        }
        this.state = initialState
        this.onDeliverChanged = this.onDeliverChanged.bind(this)
        this.onQTYChanged = this.onQTYChanged.bind(this)
        this.loadShippingRates = _.debounce(this.loadShippingRates, 900).bind(this)
    }

    componentDidMount() {
        if (this.props.post) {
            this.loadShippingRates()
            if (this.props.post.location_coordinates) {
                loadLocationForLatLng(this.props.post.location_coordinates.coordinates[1],
                    this.props.post.location_coordinates.coordinates[0]).then((location) => {
                        if ((location.zip || location.city) && !this.state.defaultZipOrCity) {
                            this.setState({ defaultZipOrCity: location.zip || location.city || '' })
                        }
                    }).catch(() => { })
            }
        } else {
            this.setState({ defaultZipOrCity: this.context?.zip || this.context?.city || '' })
        }
        if (this.props.draft) {
            this.loadShippingRates()
        }
        if (this.context && this.context.phone_number) {
            this.setState({ phoneNumber: this.context.phone_number })
        }
        if (this.context && !this.context?.city) {
            this.setState({ showZipRequiredPopup: true })
        }
    }

    private copiedToast: ModFindToast | null = null

    private navigateToPost(post: Post) {
        this.props.history.push(getPostUrl(post))
    }

    private navigateAfterDelete() {
        this.props.history.push('/my-account')
    }

    private getLocationObjectFromPost(post: Partial<Post>): LocationDetails | undefined {
        if (post.location_coordinates && post.location_address) {
            return {
                formatted_address: post.location_address,
                location: {
                    latitude: post.location_coordinates.coordinates[1],
                    longitude: post.location_coordinates.coordinates[0],
                },
                city: post.location_pickup_state || ''
            };
        }
        return undefined;
    }

    private getImagesFromPost(post: Partial<Post>): RemoteImage[] {
        return post.images ? post.images.map((img, i) => {
            return {
                file: {
                    _fc_remote: true,
                    url: img.type == 'video' ? img.thumbnail_url : img.image_url,
                    id: img.id!
                }, position: i,
                type: img.type
            }
        }) : []
    }

    private onDeliverChanged() {
        const { will_ship, will_pickup } = this.state

        if (will_ship && !will_pickup) this.setState({
            will_pickup: !this.state.will_pickup,
            will_ship: !will_ship
        })
        else this.setState({
            will_ship: !will_ship,
        })
    }

    private onPickupChanged() {
        const { will_ship, will_pickup } = this.state

        if (!will_ship && will_pickup) this.setState({
            will_pickup: !will_pickup,
            will_ship: !will_ship
        })
        else this.setState({
            will_pickup: !will_pickup,
        })
    }

    private onQTYChanged(checked: boolean) {
        this.setState({ type: checked ? PostType.Multiple : PostType.Single })
    }

    private validateDraft() {
        let validOrError: string | true = true
        if (this.state.title.trim() == '') {
            validOrError = t("ErrorMessages.CreatePost.NoTitleGiven") as string
        }
        return validOrError
    }

    private getVariants(): Variants | undefined {
        if (!this.state.enableVariants) {
            return undefined
        }
        if (this.state.variants)
            return {
                variants: this.state.variants.variants.filter((v) => {
                    if (v.name.length == 0 && v.options.length == 0) {
                        return false
                    }
                    return true
                }),
                pricing: this.state.variants.pricing,
                disabled: this.state.variants.disabled,
                quantities: this.state.variants.quantities
            }
        return undefined
    }

    private validatePost(setState: boolean = true) {
        let validOrError: string | true = true
        if (this.state.images.length == 0) {
            validOrError = t("ErrorMessages.CreatePost.NoImages") as string
        } else if (this.state.title.trim().length < 10) {
            validOrError = t("ErrorMessages.CreatePost.NoTitle") as string
        } else if (this.state.description.trim().length < 10) {
            validOrError = t("ErrorMessages.CreatePost.NoDescription") as string
        } else if (!this.state.category) {
            validOrError = t("ErrorMessages.CreatePost.NoCategory") as string
        } else if (this.state.price.length == 0 || unformatPriceI18n(this.state.price, this.state.currency) == 0) {
            validOrError = t("ErrorMessages.CreatePost.NoPrice") as string
        } else if (this.state.kickback_price && unformatPriceI18n(this.state.price, this.state.currency) < unformatPriceI18n(this.state.kickback_price, this.state.currency)) {
            validOrError = t("ErrorMessages.CreatePost.InvalidKickback") as string
        } else if (this.state.phoneNumber.length == 0) {
            validOrError = t("ErrorMessages.CreatePost.NoPhone") as string
        } else if (this.state.will_pickup && !this.state.location) {
            validOrError = t("ErrorMessages.CreatePost.invalidZipcode") as string
        } else if (this.isCarsForSale) {
            const { REQUIRED } = getPropertiesForCarsForSale()
            REQUIRED.forEach(title => {
                const key = getKeyForProperty(title)
                if (!this.state.item_attributes || !this.state.item_attributes[key]) {
                    validOrError = t("ErrorMessages.CreatePost.NoCarAttributes") as string
                }
            })
            if (this.state.selectedYears.length == 0) {
                validOrError = t("ErrorMessages.CreatePost.NoYear") as string
            }
        } else if (this.state.enableVariants) {
            const variants = this.getVariants()
            variants?.variants.forEach((variant) => {
                if (variant.name.trim().length == 0) {
                    validOrError = t("ErrorMessages.CreatePost.NoVariantName") as string
                } else if (variant.options.length == 0) {
                    validOrError = t("ErrorMessages.CreatePost.NoVariantOptions") as string
                }
            })
        } else if (includesEmail(this.state.description)) {
            validOrError = t("ErrorMessages.CreatePost.DescriptionContainsEmailOrPhone") as string
        } else if (includesPhoneNumber(this.state.description)) {
            validOrError = t("ErrorMessages.CreatePost.DescriptionContainsEmailOrPhone") as string
        }
        if (validOrError && this.state.calculate_shipping_price_at_checkout) {
            if (!this.state.shipping_package_length || this.state.shipping_package_length.length == 0 || Number.isNaN(parseFloat(this.state.shipping_package_length))) {
                validOrError = t("ErrorMessages.CreatePost.ShippingMissingData") as string
            }
            if (!this.state.shipping_package_weight || this.state.shipping_package_weight.length == 0 || Number.isNaN(parseFloat(this.state.shipping_package_weight))) {
                validOrError = t("ErrorMessages.CreatePost.ShippingMissingData") as string
            }
            if (!this.state.shipping_package_height || this.state.shipping_package_height.length == 0 || Number.isNaN(parseFloat(this.state.shipping_package_height))) {
                validOrError = t("ErrorMessages.CreatePost.ShippingMissingData") as string
            }
            if (!this.state.shipping_package_width || this.state.shipping_package_width.length == 0 || Number.isNaN(parseFloat(this.state.shipping_package_width))) {
                validOrError = t("ErrorMessages.CreatePost.ShippingMissingData") as string
            }
            if (!this.state.shipping_rate_id) {
                validOrError = t("ErrorMessages.CreatePost.ShippingMissingData") as string
            }
        }
        let newValid = validOrError === true ? true : false
        if (setState && this.state.valid != newValid) {
            this.setState({ valid: newValid })
        }
        return validOrError
    }

    private determineDelivery() {
        if (this.state.will_ship && this.state.will_pickup) return DeliveryMethod.PICKUP_SHIP
        else if (this.state.will_ship && !this.state.will_pickup) return DeliveryMethod.SHIP
        else return DeliveryMethod.PICKUP
    }

    private loadShippingRates() {
        const height = systemToMetricLength(this.state.shipping_package_height)
        const width = systemToMetricLength(this.state.shipping_package_width)
        const length = systemToMetricLength(this.state.shipping_package_length)
        const weight = systemToMetricLength(this.state.shipping_package_weight)
        if (height && length && weight && width) {
            this.setState({ loading_shipping_rates: true })
            PostsAPI.getShippingRates({ height, weight, width, length }).then((rates) => {
                let shipping_rate_id = this.state.shipping_rate_id
                if (shipping_rate_id && !rates.map(i => i.id).includes(shipping_rate_id)) {
                    shipping_rate_id = undefined
                }
                this.setState({ shipping_rate_id: shipping_rate_id, shipping_rates: rates, loading_shipping_rates: false }, () => this.validatePost())

            }).catch(() => {
                this.setState({ shipping_rate_id: undefined, shipping_rates: undefined, loading_shipping_rates: false }, () => this.validatePost())
            })
        } else {
            this.setState({ shipping_rate_id: undefined, shipping_rates: undefined }, () => this.validatePost())
        }
    }


    private async saveDraft() {
        this.setState({ posting: true })
        let post: CreatePost = this.getPost() //send this post to API!

        this.uploadImages(post).then((ids) => {
            const then = (res: PostDraft) => {
                this.props.history.push('/my-account')
                toastr.success(t("Messages.SAVED_DRAFTS_LOCATION"), t("Messages.DRAFT_SAVED"))
            }
            const catchError = (e: Error) => {
                let feedback = (this.props.post && this.props.post.id ? 'Update Draft' : 'Create Draft') + ' failed'
                this.setState({ apiError: feedback, posting: false })
            }
            let cords: [number, number] | undefined = post.location && [post.location.location.longitude, post.location.location.latitude];
            let locationObj = post.location;
            let stateComp = locationObj?.city || locationObj?.state
            let postParams = {
                images: ids,
                title: post.title || '',
                description_text: post.description || '',
                location_coordinates: this.state.will_pickup ? cords : undefined,
                location_address: this.state.will_pickup ? locationObj?.formatted_address : undefined,
                location_pickup_state: this.state.will_pickup ? stateComp : undefined,
                price: post.price,
                brands: post.brands ? post.brands : [],
                cars: post.cars ? post.cars : [],
                categories: post.categories || [],
                kickback_price: post.kickback_price,
                shipping_price: post.shipping_price,
                status: this.state.status as PostStatus.Public | PostStatus.Pending | PostStatus.PrivateSold,
                subCategory: post.subCategory,
                item_attributes: post.item_attributes,
                variants: post.variants,
                delivery_method: this.determineDelivery(),
                quantity: post.quantity,
                type: post.type,
                calculate_shipping_price_at_checkout: this.state.calculate_shipping_price_at_checkout,
                shipping_package_height: systemToMetricLength(this.state.shipping_package_height),
                shipping_package_length: systemToMetricLength(this.state.shipping_package_length),
                shipping_package_weight: systemToMetricWeight(this.state.shipping_package_weight),
                shipping_package_width: systemToMetricLength(this.state.shipping_package_width),
                shipping_rate_id: this.state.shipping_rate_id
            }
            if (this.props.draft && this.props.draft.id) {
                PostsAPI.updatePostDraft(this.props.draft.id, { ...postParams, images: ids })
                    .then(then)
                    .catch(catchError);
            } else {
                PostsAPI.createPostDraft({ ...postParams, images: ids })
                    .then(then)
                    .catch(catchError);
            }
        })
    }

    private async post() {
        this.setState({ posting: true })
        let post: CreatePost = this.getPost() //send this post to API!
        if (this.context && this.context.phone_number != this.state.phoneNumber && this.state.phoneNumber.length > 0) {
            try {
                const u = await UsersAPI.updateProfile({ phone_number: this.state.phoneNumber })
                UserSession.getUserUpdatedHandler()(u)
            } catch (err) {
                toastr.error(errorToString(err as APIError).join('\n'))
                return
            }
        }
        this.uploadImages(post).then((ids) => {
            const updated = this.props.post && this.props.post.id ? true : false;
            let cords: [number, number] | undefined = post.location && [post.location.location.longitude, post.location.location.latitude];
            let locationObj = post.location;
            if (post.price && post.categories && post.categories.length > 0) {
                let then = (res: Post) => {
                    PostsAPI.getKickbackLink(res.id).then(link => {
                        this.setState({ createdPost: { post: res, link, updated }, posting: false })
                    }).catch(() => {
                        this.setState({ createdPost: { post: res, updated }, posting: false })
                    })
                }
                let catchError = (e: Error) => {
                    const feedback = (this.props.post && this.props.post.id ? 'Update Post' : 'Create Post') + ' failed'
                    this.setState({ apiError: feedback, posting: false })
                }
                let stateComp = locationObj?.city || locationObj?.state
                let postParams = {
                    images: ids,
                    title: post.title || '',
                    description_text: post.description || '',
                    location_coordinates: this.state.will_pickup ? cords : undefined,
                    location_address: this.state.will_pickup ? locationObj?.formatted_address : undefined,
                    location_pickup_state: this.state.will_pickup ? stateComp : undefined,
                    price: post.price,
                    brands: post.brands ? post.brands : [],
                    cars: post.cars ? post.cars : [],
                    categories: post.categories || [],
                    kickback_price: post.kickback_price,
                    shipping_price: this.state.will_ship ? post.shipping_price : undefined,
                    status: this.state.status as PostStatus.Public | PostStatus.Pending | PostStatus.PrivateSold,
                    subCategory: post.subCategory,
                    item_attributes: post.item_attributes,
                    variants: post.variants,
                    delivery_method: this.determineDelivery(),
                    draft_id: this.props.draft?.id,
                    apply_vat: false,
                    quantity: post.quantity,
                    type: post.type,
                    calculate_shipping_price_at_checkout: this.state.calculate_shipping_price_at_checkout,
                    shipping_package_height: systemToMetricLength(this.state.shipping_package_height),
                    shipping_package_length: systemToMetricLength(this.state.shipping_package_length),
                    shipping_package_weight: systemToMetricWeight(this.state.shipping_package_weight),
                    shipping_package_width: systemToMetricLength(this.state.shipping_package_width),
                    shipping_rate_id: this.state.shipping_rate_id
                }

                if (this.props.post && this.props.post.id) {
                    PostsAPI.updatePost(this.props.post.id, postParams)
                        .then(then)
                        .catch(catchError);
                } else {
                    PostsAPI.createPost(postParams)
                        .then(then)
                        .catch(catchError);
                }
            } else {
                this.setState({ apiError: 'Location is invalid' })
            }
        }).catch(err => {
            this.setState({ posting: false, apiError: 'Error uploading images.' })
        })
    }

    private uploadImages(post: CreatePost) {
        if (post.images) {
            return Promise.all(post.images.map(image => {
                if (isRemoteFile(image)) {
                    return image.id
                } else {
                    return this.uploadImage(image)
                }
            }))
        } else {
            return Promise.resolve([] as string[])
        }
    }

    private uploadImage(image: File) {
        let promise = Promise.resolve({ id: '' })

        if (image.type.startsWith('video')) {
            promise = PostsAPI.uploadPostVideo(image)
        } else {
            promise = PostsAPI.uploadPostImage(image)
        }
        return promise.then((result) => {
            return result.id
        })
    }

    private deletePost() {

        this.props.post && PostsAPI.deletePost(this.props.post.id).then(() => {
            this.setState({ deletePost: true, deletePostLoading: false })
        }).catch((e) => {
            let feedback = 'Delete Post failed'
            this.setState({ apiError: feedback, deletePostLoading: false })
        })
    }

    private getPost(): CreatePost {
        let images = this.state.images

        return {
            id: this.props.post && this.props.post.id,
            title: this.state.title,
            description: this.state.description,
            location: this.state.location,
            images: this.state.images.sort((i1, i2) => i1.position - i2.position).map(i => i.file),
            brands: this.state.selectedBrands.map(b => b.id),
            cars: this.state.selectedCars,
            categories: this.state.category ? [this.state.category!.id] : [],
            subCategory: this.state.subcategory ? this.state.subcategory.id : undefined,
            price: unformatPriceI18n(this.state.price, this.state.currency),
            kickback_price: this.state.kickback_price.length > 0 ? unformatPriceI18n(this.state.kickback_price, this.state.currency) : undefined,
            shipping_price: this.state.shipping_price.length > 0 ? unformatPriceI18n(this.state.shipping_price, this.state.currency) : undefined,
            delivery_method: this.state.delivery_method,
            item_attributes: this.state.item_attributes,
            variants: this.getVariants(),
            //order: order,
            status: this.props.post && this.props.post.status,
            type: this.state.type,
            quantity: this.state.quantity
        }
    }

    private renderAPIErrorPopup() {
        if (this.state.apiError) {
            return (
                <ModFindPopUp
                    onClose={() => { this.setState({ apiError: undefined }) }}>
                    <TitleMessageContent title={t("ErrorMessages.CreatePost.GenericError")} message={this.state.apiError} />
                </ModFindPopUp>
            )
        }
        return null
    }

    private renderPostCreatedOrUpdated() {
        if (this.state.createdPost && !this.state.apiError && !this.state.featured_item) {
            return (
                <ModFindPopUp
                    onClose={() => { this.navigateToPost(this.state.createdPost!.post) }}>
                    <TitleMessageContent
                        icon={'success'}
                        title={t("Messages.CONGRATS")}
                        message={this.state.createdPost.updated ? t("Messages.POST_UPDATED_TITLE") : t("Messages.POST_CREATED_TITLE")}
                        buttons={[{
                            text: t("Messages.VIEW"), onClick: () => {
                                this.navigateToPost(this.state.createdPost!.post)
                            },
                        },
                        this.state.createdPost.link ?
                            {
                                text: <Popup content={<>
                                    <ModFindShare url={this.state.createdPost.link} copiedLink={() => { this.copiedToast && this.copiedToast.show(t("Messages.COPIED")) }} />
                                    <ModFindToast ref={(ref) => this.copiedToast = ref} position={'left'} />
                                </>
                                }
                                    on='click' position='top center' className="share-pop-up"
                                    trigger={<Button
                                        text={'Share'}
                                        size={'sm'}
                                        rounded
                                        className="share-button"
                                        classNameText="share-text"
                                    />} />
                            } : undefined!,
                        {
                            text: t("Messages.POST_AGAIN"), onClick: () => {
                                window.location.reload()
                            },
                        }].filter(Boolean)} />
                </ModFindPopUp>
            )
        }
        return null
    }

    private renderPostDeletePopUp() {
        if (this.state.deletePost) {
            return (
                <ModFindPopUp
                    onClose={() => { this.navigateAfterDelete() }}>
                    <TitleMessageContent title={t("Messages.POST_DELETED_TITLE")} />
                </ModFindPopUp>
            )
        }
    }

    private statusDataSource() {
        let ds = {
            [PostStatus.Pending]: 'Pending',
            [PostStatus.PrivateSold]: 'Private Sold',
            [PostStatus.Public]: 'Still for Sale'
        }
        return Object.keys(ds).map(item => {
            return {
                id: item,
                name: ds[item as (PostStatus.Pending | PostStatus.PrivateSold | PostStatus.Public)]
            }
        })
    }

    private renderChangeZip() {
        if (this.state.showZipRequiredPopup) {
            return <ZipCodeRequiredPopup user={this.context!} onClose={() => {
                if (this.props.history.length > 2) {
                    this.props.history.goBack()
                } else {
                    this.props.history.replace('/')
                }
            }} onUserUpdated={() => {
                this.setState({ showZipRequiredPopup: false })
            }} />
        }
        return null
    }

    private renderPostFeatureCheckout() {
        if (this.state.createdPost && this.state.featured_item) {
            return (
                <ModFindPopUp
                    onClose={() => {
                        this.setState({ featured_item: false })
                    }}>
                    <FeaturedCheckout post={this.state.createdPost.post} onCancel={() => {
                        this.setState({ featured_item: false })
                    }} onSuccess={() => {
                        this.setState({ featured_item: false })
                    }} />
                </ModFindPopUp>
            )
        }
    }

    private getVariantsXVariants(): VariantOption[][] {
        if (!this.state.enableVariants) {
            return []
        }
        const variants = this.getVariants()
        if (variants?.variants.length) {
            const a = variants.variants.map(v => v.options).filter(f => f.length)
            if (a.length === 0) {
                return []
            }
            const cartesian = a.reduce((a, b) => {
                return a.flatMap(d => b.map(e => [d, e].flat())) as any;
            })
            if (cartesian.find(c => !Array.isArray(c))) {
                return cartesian.map((c) => [c])
            }
            return cartesian as any
        }
        return []
    }

    private renderVariants() {
        if (!this.state.enableVariants) {
            return null
        }
        const { variants } = this.state
        const xProduct = this.getVariantsXVariants()
        return <div>
            <div className='variants'>
                {(variants?.variants || []).map((variant) => {
                    return <EditVariant key={variant.id} disabled={this.state.posting} name={variant.name} options={variant.options} onUpdated={(name, options) => {
                        variant.name = name;
                        variant.options = options;
                        this.setState({ variants: { ...variants! } })
                    }} onRemove={() => {
                        const remove = () => {
                            const newVariants = variants!.variants!.filter((v) => v.id! != variant.id)
                            if (newVariants.length == 0) {
                                newVariants.push({ id: uuid.v1(), name: '', options: [], required: false })
                            }
                            this.setState({ variants: { ...variants!, variants: newVariants } })
                        }
                        if (variant.name.length == 0 && variant.options.length == 0) {
                            remove()
                        } else {
                            Alert.show(t('Messages.OPTION_REMOVE_CONFIRM'), remove, () => { })
                        }
                    }} onAddVariant={() => {
                        this.setState({ variants: { ...variants!, variants: [...variants?.variants!, { id: uuid.v1(), name: '', options: [], required: false }] } })
                    }} currency={this.state.currency} />
                })}
            </div>
            {xProduct.length > 0 && <div className='variant-pricing'>
                <div>{t("Messages.COMBINATIONS")}</div>
                {xProduct.map((options) => {
                    const ids = options.map(o => o.id)
                    let price = variants?.pricing.find((price) => {
                        return compareVariantArray(ids, price.options);
                    })
                    let quantity = variants?.quantities.find((price) => {
                        return compareVariantArray(ids, price.options);
                    })
                    let disabled = variants?.disabled.find((price) => {
                        return compareVariantArray(ids, price.options);
                    }) != undefined
                    return <div key={ids.join()} className={"option-pricing-row " + (disabled ? 'disabled' : '')}>
                        <div className='option-pricing-action'>
                            {!disabled && <span className='pointer' onClick={() => {
                                variants!.disabled.push({ options: ids })
                                this.setState({ variants: { ...variants! } })
                            }}>
                                <img src={IC_MINUS_BORDER} width={19} />
                            </span>}
                            {disabled && <span className='pointer' onClick={() => {
                                variants!.disabled = variants!.disabled.filter((f) => !compareVariantArray(ids, f.options))
                                this.setState({ variants: { ...variants! } })
                            }}>
                                <img src={IC_PLUS_BORDER} width={19} />
                            </span>}
                        </div>
                        <div className="option-pricing-value">
                            {options.map(n => n.name).join(' / ')}
                        </div>
                        <div className="option-pricing-price">
                            <ModFindCurrencyInput
                                onValueChanged={(value) => {
                                    if (!price) {
                                        variants?.pricing.push({ options: ids, price: '0.00' })
                                        price = variants!.pricing[variants!.pricing.length - 1]
                                    }
                                    if (value) {
                                        price.price = unformatPriceI18n(value, this.state.currency).toFixed(2)
                                    } else {
                                        variants!.pricing = variants!.pricing.filter((v) => !compareVariantArray(ids, v.options))
                                    }
                                    this.setState({ variants: { ...variants! } })
                                }}
                                placeholder={t("Messages.PRICE")}
                                value={price?.price ? formatPriceI18n(price?.price, this.state.currency) : ''}
                                colored
                                rounded
                                style={{ margin: "8px 0px 4px" }}
                                currency={this.state.currency}
                            />
                        </div>
                        {this.state.type != PostType.Single && <div className="option-pricing-qty">
                            <ModFindInput disabled={this.state.posting}
                                placeholder={t("Messages.SET_QTY")}
                                colored
                                rounded
                                className="small-input-qty"
                                value={quantity?.quantity || ''}
                                onValueChanged={(value) => {
                                    if (!quantity) {
                                        variants?.quantities.push({ options: ids, quantity: 0 })
                                        quantity = variants!.quantities[variants!.quantities.length - 1]
                                    }
                                    if (value) {
                                        quantity.quantity = parseInt(value) || 0
                                    } else {
                                        variants!.quantities = variants!.quantities.filter((v) => !compareVariantArray(ids, v.options))
                                    }
                                    this.setState({ variants: { ...variants! } })
                                }} />
                        </div>}
                    </div>
                })}
            </div>}
        </div>
    }

    render() {
        return (
            <Box>
                {this.renderChangeZip()}
                {this.renderAPIErrorPopup()}
                {this.renderPostFeatureCheckout()}
                {this.renderPostCreatedOrUpdated()}
                {this.renderPostDeletePopUp()}
                <div className={`selling-container ${this.props.post ? ' edit-mode' : ''}`}>
                    <div className="header">
                        <span className="headline">{t("Messages.SELLING_TODAY")}</span>
                    </div>

                    <ModFindImageGrid images={this.state.images} onImagesChanged={(images) => {
                        this.setState({ images }, () => {
                            this.validatePost()
                        })
                    }} disabled={this.state.posting} />
                    <div className="details-container action-list">
                        <SeparatorBox direction='row'>
                            <ModFindInput disabled={this.state.posting}
                                placeholder={t("Messages.ADD_TITLE") + '*'}
                                inputClassName="add-title-input"
                                value={this.state.title}
                                onValueChanged={(value) => {
                                    this.setState({ title: value }, () => {
                                        this.validatePost()
                                    })
                                }} />
                            {this.state.title.trim().length < 10 && <span className="counter-style">{`${this.state.title.trim().length}/10`}</span>}
                        </SeparatorBox>
                        <SeparatorBox>
                            <ModFindTextarea disabled={this.state.posting}
                                placeholder={t("Messages.DESCRIBE") + '*'} value={this.state.description}
                                inputClassName="describe-input"
                                onValueChanged={(value) => {
                                    this.setState({ description: value }, () => {
                                        this.validatePost()
                                    })
                                }}
                            />
                            {this.state.description.trim().length < 10 && <span className="counter-style">{`${this.state.description.trim().length}/10`}</span>}
                        </SeparatorBox>

                        <SeparatorBox>
                            <span className="item delivery-method">{t("Messages.DELIVERY_METHOD")}</span>
                            <div className={'switch-wrapper'}>
                                <span className="switcher-label">{t("Messages.WILL_SHIP")}</span>
                                <Switch
                                    checked={this.state.will_ship}
                                    onChange={() => this.onDeliverChanged()}
                                    offColor={Colors.GREY}
                                    onColor={Colors.DODGER_BLUE}
                                    onHandleColor={'#fff'}
                                    offHandleColor={'#fff'}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    handleDiameter={24}
                                    width={52}
                                />
                            </div>
                            <div className={'switch-wrapper'}>

                                <span className="switcher-label">{t("Messages.WILL_PICKUP")}</span>
                                <Switch
                                    checked={this.state.will_pickup}
                                    onChange={() => this.onPickupChanged()}
                                    offColor={Colors.GREY}
                                    onColor={Colors.DODGER_BLUE}
                                    onHandleColor={'#fff'}
                                    offHandleColor={'#fff'}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    handleDiameter={24}
                                    width={52}
                                />
                            </div>
                        </SeparatorBox>

                        <SeparatorBox className={this.state.will_pickup ? "pick-up-on" : "pick-up-off"}>
                            <div className={`pickup-location-input`}>
                                <span>{`${t("Messages.ITEM_LOCATION")}*`}</span>
                                <ZipCodeInput region={this.context?.region_code} defaultZipOrCity={this.state.defaultZipOrCity} className="small-input"
                                    onLocation={location => {
                                        this.setState({ location: location as LocationDetails })
                                    }} />

                                <span className="selling-info">{t("Messages.LOCATION_DESCRIPTION")}</span>
                            </div>
                        </SeparatorBox>



                        <SeparatorBox
                            direction={'row'}>
                            <span className="item">{t("Messages.CATEGORY") + '*'}</span>
                            <span className="item blue right">
                                <CategoryDropDown disabled={this.state.posting}
                                    selected={this.state.category ? [this.state.category] : []} onSelectedItemsChange={(e) => {
                                        const category = e.length ? e[0] : undefined
                                        this.setState({ category: category }, () => {
                                            this.validatePost()
                                        })
                                    }} />
                            </span>
                        </SeparatorBox>
                        {this.state.category && this.state.category.sub_categories.length > 0 && <SeparatorBox
                            direction={'row'}
                        >
                            <span className="item">{t("Messages.SUBCATEGORY")}</span>
                            <span className="item blue"><SubCategoryDropDown items={this.state.category.sub_categories} disabled={this.state.posting}
                                onSelectedItemsChange={(e) => {
                                    this.setState({ subcategory: e.length ? e[0] : undefined }, () => {
                                        this.validatePost()
                                    })
                                }} selected={this.state.subcategory ? [this.state.subcategory] : []} /></span>
                        </SeparatorBox>}
                        <SeparatorBox direction={'row'}>
                            <span className="item">{t("Messages.YEARS")}</span>
                            <span className="item blue right">
                                <YearDropDown
                                    className={'filter-dropdown'}
                                    allowMultiSelect
                                    selected={(this.state.selectedYears || []).map((year) => ({ id: year, name: year }))}
                                    onSelectedItemsChange={(selected) => {
                                        this.setState(({ selectedYears: selected.map(s => s.id) }))
                                    }}
                                />
                            </span>
                        </SeparatorBox>
                        <SeparatorBox direction={'row'}>
                            <span className="item">{t("Messages.MAKES")}</span>
                            <span className="item blue right">
                                <BrandDropDown disabled={this.state.posting}
                                    selected={this.state.selectedBrands} onSelectedItemsChange={(e) => {
                                        this.setState({ selectedBrands: e }, () => {
                                            this.validatePost()
                                        })
                                    }} allowMultiSelect />
                            </span>
                        </SeparatorBox>
                        <SeparatorBox direction={'row'}>
                            <span className="item">{t("Messages.MODEL_TYPES")}</span>
                            <span className="ModelTypeDropdown-Wrapper">
                                <ModelTypeDropDown
                                    disabled={this.state.posting}
                                    filteredBrands={this.state.selectedBrands}
                                    selected={this.state.selectedCars} filteredYears={this.state.selectedYears}
                                    onSelectedItemsChange={(e) => {
                                        this.setState({ selectedCars: e }, () => {
                                            this.validatePost()
                                        })
                                    }} showYears />
                            </span>
                        </SeparatorBox>
                        {this.renderAdditionalProperties()}
                        {this.props.post && <SeparatorBox direction={'row'}>
                            <span className="item">{t("Messages.STATUS")}</span>
                            <span className="item blue right">
                                <StaticSourceDropDown disabled={this.state.posting} items={this.statusDataSource()}
                                    selected={this.state.status ? [{ id: this.state.status, name: '' }] : []} onSelectedItemsChange={(e) => {
                                        this.setState({ status: e[0].id as PostStatus }, () => {
                                            this.validatePost()
                                        })
                                    }} />
                            </span>
                        </SeparatorBox>}
                        <SeparatorBox direction={'row'} className='medium-flex-column'>
                            <div className="price-box">
                                <span>{t("Messages.SET_PRICE") + '*'}</span>
                                <ModFindCurrencyInput disabled={this.state.posting}
                                    onValueChanged={(value) => {
                                        this.setState({ price: value }, () => {
                                            this.validatePost()
                                        })
                                    }}
                                    value={this.state.price}
                                    colored
                                    rounded
                                    className="small-input"
                                    currency={this.state.currency}
                                />
                            </div>
                            <div className="price-box">
                                <span>{t("Messages.OFFER_KICKBACK")}</span>
                                <ModFindCurrencyInput disabled={this.state.posting}
                                    onValueChanged={(value: string) => {
                                        this.setState({ kickback_price: value }, () => {
                                            this.validatePost()
                                        })
                                    }}
                                    value={this.state.kickback_price}
                                    colored
                                    rounded
                                    className="small-input"
                                    currency={this.state.currency}
                                />
                                <span className="selling-info">{t("Messages.KICKBACK_INFO")}</span>
                            </div>
                            <div className="price-box">
                                <span>{t("Messages.PHONE") + '*'}</span>
                                <ModFindInput
                                    type={'number'}
                                    className="small-input"
                                    value={this.state.phoneNumber}
                                    colored
                                    rounded
                                    onValueChanged={(value) => {
                                        this.setState({ phoneNumber: value })
                                    }}
                                />
                                <span className="selling-info">{t("Messages.BUY_PHONE_INFO")}</span>
                            </div>
                        </SeparatorBox>
                        <SeparatorBox direction={'row'} className='medium-flex-column'>
                            <div className="adding-features-switcher-wrapper">
                                <span className="switcher-label">{t("Messages.SET_QTY")}</span>
                                <Switch disabled={(this.props.post && this.props.post.type != PostType.Single)}
                                    checked={this.state.type != PostType.Single}
                                    onChange={this.onQTYChanged}
                                    offColor={Colors.GREY}
                                    onColor={Colors.DODGER_BLUE}
                                    onHandleColor={'#fff'}
                                    offHandleColor={'#fff'}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    handleDiameter={24}
                                    width={52}
                                />
                            </div>
                            {this.state.type != PostType.Single && <ModFindInput disabled={this.state.posting}
                                placeholder={t("Messages.SET_QTY")}
                                colored
                                rounded
                                className="small-input-qty"
                                value={this.state.quantity}
                                onValueChanged={(value) => {
                                    this.setState({ quantity: parseInt(value) || 0 }, () => {
                                        this.validatePost()
                                    })
                                }} onBlur={() => {
                                    if (this.state.quantity == 0 && this.state.type == PostType.Multiple)
                                        this.setState({ quantity: 1 }, () => {
                                            this.validatePost()
                                        })
                                }} />}
                        </SeparatorBox>
                        <SeparatorBox direction={'column'} className='medium-flex-column'>
                            <div className="adding-features-switcher-wrapper">
                                <span className="switcher-label">{t("Messages.VARIANTS")}</span>
                                <Switch
                                    checked={this.state.enableVariants}
                                    onChange={(e) => {
                                        this.setState({ enableVariants: e })
                                    }}
                                    offColor={Colors.GREY}
                                    onColor={Colors.DODGER_BLUE}
                                    onHandleColor={'#fff'}
                                    offHandleColor={'#fff'}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    handleDiameter={24}
                                    width={52}
                                />
                            </div>
                            {this.renderVariants()}
                        </SeparatorBox>
                        {this.renderShippingPrice()}
                        {!this.props.post && <SeparatorBox direction={'row'} className='medium-flex-column'>
                            <div className="featured-item-switcher-wrapper">
                                <span className="switcher-label">{t("Messages.FEATURED_ITEM")}</span>
                                <Switch
                                    checked={this.state.featured_item}
                                    onChange={(val) => this.setState({ featured_item: val })}
                                    offColor={Colors.GREY}
                                    onColor={Colors.DODGER_BLUE}
                                    onHandleColor={'#fff'}
                                    offHandleColor={'#fff'}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    handleDiameter={24}
                                    width={52}
                                />
                            </div>
                            <div className="featured-items-wrapper">
                                <div className='featured-info'>{t("Messages.FEATURED_ITEM_INFO")}</div>
                                <div className='featured-item-info'>
                                    <img src={IC_CHECK_BLUE} />
                                    <span>{t("Messages.FEATURED_ITEM_ADV_1")}</span>
                                </div>
                                <div className='featured-item-info'>
                                    <img src={IC_CHECK_BLUE} />
                                    <span>{t("Messages.FEATURED_ITEM_ADV_2")}</span>
                                </div>
                                <div className='featured-item-info'>
                                    <img src={IC_CHECK_BLUE} />
                                    <span>{t("Messages.FEATURED_ITEM_ADV_3")}</span>
                                </div>
                                <div className='featured-item-info'>
                                    <img src={IC_CHECK_BLUE} />
                                    <span>{t("Messages.FEATURED_ITEM_ADV_4")}</span>
                                </div>
                                <div className="selling-info featured-items-additional-info">{t("Messages.FEATURED_ITEM_INFO_2")}</div>
                            </div>
                        </SeparatorBox>}
                        <div className="selling-buttons-block">
                            {this.props.post && <Button
                                text={t("Messages.DELETE")}
                                size={'lg'}
                                rounded
                                className="delete-button"
                                loading={this.state.deletePostLoading}
                                onClick={() => {
                                    this.setState({ deletePostLoading: true })
                                    this.deletePost()
                                }}
                            />}
                            {!this.props.post && <Popup content={this.validateDraft() == true ? undefined : this.validateDraft()} disabled={this.validateDraft() === true} trigger={<Button
                                className="save-button"
                                text={t("Messages.SAVE_AS_DRAFT")}
                                size={'lg'}
                                rounded
                                disabled={(this.validateDraft() !== true) || this.state.posting}
                                loading={this.state.posting}
                                onClick={() => {
                                    this.saveDraft()
                                }}
                            />} />}
                            <Popup content={this.validatePost(false) == true ? undefined : this.validatePost(false)} disabled={this.validatePost(false) === true} trigger={<Button
                                className="post-item"
                                text={this.props.post ? t("Messages.UPDATE_ITEM") : t("Messages.POST")}
                                size={'lg'}
                                rounded
                                disabled={(!this.state.valid) || this.state.posting}
                                loading={this.state.posting}
                                onClick={() => {
                                    this.post()
                                }}
                            />} />
                        </div>
                        <div className="duplicate-relist-button-wrapper">
                            {this.props.post && <Button
                                text={this.props.post.status == PostStatus.Public ? t("Messages.DUPLICATE") : t("Messages.RELIST")}
                                size={'lg'}
                                rounded className="dupicate-relist-button"
                                loading={this.state.posting}
                                disabled={this.state.posting}
                                onClick={() => {
                                    this.setState({ posting: true })
                                    PostsAPI.duplicatePost(this.props.post!.id).then(res => {
                                        this.setState({ createdPost: { post: res, updated: false }, posting: false })
                                    }).catch(err => {
                                        const feedback = (this.props.post!.status == PostStatus.Public ? t("Messages.DUPLICATE") : t("Messages.RELIST")) + ' failed'
                                        this.setState({ apiError: feedback, posting: false })
                                    })
                                }}
                            />}
                        </div>
                    </div>
                </div>
            </Box>
        )
    }

    renderShippingPrice() {
        if (!this.state.will_ship) {
            return null
        }
        const switchComponent = (<SeparatorBox direction={'row'} className='medium-flex-column last-input-container'>
            <div className="adding-features-switcher-wrapper">
                <span className="switcher-label">{t("Messages.CALC_SHIPPING_AT_CHECKOUT")}</span>
                <Switch
                    checked={this.state.calculate_shipping_price_at_checkout}
                    onChange={(checked) => {
                        this.setState({ calculate_shipping_price_at_checkout: checked }, () => this.loadShippingRates())
                    }}
                    offColor={Colors.GREY}
                    onColor={Colors.DODGER_BLUE}
                    onHandleColor={'#fff'}
                    offHandleColor={'#fff'}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    handleDiameter={24}
                    width={52}
                />
            </div>

        </SeparatorBox>)

        if (this.state.calculate_shipping_price_at_checkout) {
            return <>
                {switchComponent}
                <SeparatorBox direction={'column'} className='medium-flex-column'>
                    <div className="dimensions-inputs-block">
                        <div className="price-box">
                            <span>{t("Messages.WIDTH")}*</span>
                            <ModFindInput disabled={this.state.posting} placeholder={`In ${lengthUnit()}`}
                                onValueChanged={(value) => {
                                    this.setState({ shipping_package_width: value }, () => {
                                        this.validatePost()
                                        this.loadShippingRates()
                                    })
                                }}
                                value={this.state.shipping_package_width}
                                colored
                                rounded
                                className="small-input"
                            />
                        </div>
                        <div className="price-box">
                            <span>{t("Messages.HEIGHT")}*</span>
                            <ModFindInput disabled={this.state.posting} placeholder={`In ${lengthUnit()}`}
                                onValueChanged={(value: string) => {
                                    this.setState({ shipping_package_height: value }, () => {
                                        this.validatePost()
                                        this.loadShippingRates()
                                    })
                                }}
                                value={this.state.shipping_package_height}
                                colored
                                rounded
                                className="small-input"
                            />
                        </div>
                        {this.state.will_ship && <div className="price-box">
                            <span>{t("Messages.LENGTH")}*</span>
                            <ModFindInput disabled={this.state.posting} placeholder={`In ${lengthUnit()}`}
                                onValueChanged={(value: string) => {
                                    this.setState({ shipping_package_length: value }, () => {
                                        this.validatePost()
                                        this.loadShippingRates()
                                    })
                                }}
                                value={this.state.shipping_package_length}
                                colored
                                rounded
                                className="small-input"
                            />
                        </div>}
                        <div className="price-box">
                            <span>{t("Messages.WEIGHT")}*</span>
                            <ModFindInput disabled={this.state.posting} placeholder={`In ${weightUnit()}`}
                                onValueChanged={(value: string) => {
                                    this.setState({ shipping_package_weight: value }, () => {
                                        this.validatePost()
                                        this.loadShippingRates()
                                    })
                                }}
                                value={this.state.shipping_package_weight}
                                colored
                                rounded
                                className="small-input"
                            />
                        </div>
                    </div>
                    <div className='shipping-info selling-info'>{t("Messages.SHIPPING_CALC_INFO")}</div>
                    {this.state.loading_shipping_rates && <div className="shipping-loader-wrapper"><ModFindLoader /></div>}
                    {!this.state.loading_shipping_rates && this.state.shipping_rates == undefined && <div className="shipping-fill-text-wrapper">{t("Messages.SHIPPING_FILL_ALL_FIELDS")}</div>}
                    {!this.state.loading_shipping_rates && this.state.shipping_rates && this.state.shipping_rates?.length == 0 && <div className="shipping-fill-text-wrapper">{t("Messages.SHIPPING_NO_METHOD_AVAIL")}</div>}
                    {!this.state.loading_shipping_rates && this.state.shipping_rates && this.state.shipping_rates?.length > 0 && <StaticSourceDropDown disabled={this.state.posting} items={this.state.shipping_rates?.map((rate) => {
                        return {
                            id: rate.id,
                            name: `${rate.provider} ${rate.name}`
                        }
                    }) || []} selected={this.state.shipping_rate_id ? [{ id: this.state.shipping_rate_id, name: '' }] : []} onSelectedItemsChange={(e) => {
                        this.setState({ shipping_rate_id: e[0].id }, () => {
                            this.validatePost()
                        })
                    }} className="delivery-dropdown" placeholder={t("Messages.DELIVERY_METHOD") + "*"} />}
                </SeparatorBox>
            </>
        } else {
            return <>
                {switchComponent}
                <SeparatorBox direction={'row'} className='medium-flex-column shipping-price-wrapper'>
                    <div className="price-box">
                        <span>{t("Messages.SHIPPING_PRICE")}</span>
                        <ModFindCurrencyInput disabled={this.state.posting}
                            onValueChanged={(value: string) => {
                                this.setState({ shipping_price: value }, () => {
                                    this.validatePost()
                                })
                            }}
                            value={this.state.shipping_price}
                            colored
                            rounded
                            className="small-input"
                            currency={this.state.currency}
                        />
                        <span className="selling-info">{t("Messages.SHIPPING_DETAIL_INFO")}</span>
                    </div>
                </SeparatorBox>
            </>
        }
    }

    renderAdditionalProperties(): React.ReactNode {
        if (!this.state.category || this.state.category.name.toLowerCase() != 'Cars for Sale'.toLowerCase()) {
            return null
        }
        const attributes = this.state.item_attributes || {}

        const getAttributeOrDefault = (key: string, defaultValue: string = '') => {
            if (attributes[key]) {
                return attributes[key].value
            }
            return defaultValue
        }

        const setAttribute = (title: string, key: string, value: string) => {
            if (!attributes[key]) {
                attributes[key] = { title, value }
            } else {
                attributes[key].value = value
            }
            this.setState({ item_attributes: attributes })
        }
        const { REQUIRED, OPTIONAL } = getPropertiesForCarsForSale()
        return [...REQUIRED, ...OPTIONAL].map(title => {
            const key = getKeyForProperty(title)
            const isReuqired = REQUIRED.includes(title)
            return <SeparatorBox key={key} direction={'row'}>
                <span className="item">{title}{isReuqired ? '*' : ''}</span>
                <span className="item blue right">
                    <ModFindInput colored rounded disabled={this.state.posting} value={getAttributeOrDefault(key)} onValueChanged={(val) => {
                        setAttribute(title, key, val)
                    }} />
                </span>
            </SeparatorBox>
        })
    }
}

const SellingRouter = function (props: RouteComponentProps<{ postId?: string, draftId?: string }>) {
    const [post, setPost] = React.useState<Post | undefined>(undefined)
    const [draft, setDraft] = React.useState<PostDraft | undefined>(undefined)

    React.useEffect(() => {
        if (props.match.params.postId) {
            PostsAPI.getPost(props.match.params.postId).then(post => {
                setPost(post)
            })
        } else if (props.match.params.draftId) {
            PostsAPI.getPostDrafts().then(drafts => {
                setDraft(drafts.find(d => d.id == props.match.params.draftId))
            })
        }
    }, [])

    if (props.match.params.postId) {
        if (post) {
            return <Selling key={post.id} post={post} {...props} />
        } else {
            return <div className="draft-and-post-loader-wrapper">
                <ClipLoader color={Colors.DODGER_BLUE} size={35} />
            </div>
        }
    } else if (props.match.params.draftId) {
        if (draft) {
            return <Selling key={draft.id} draft={draft} {...props} />
        } else {
            return <div className="draft-and-post-loader-wrapper">
                <ClipLoader color={Colors.DODGER_BLUE} size={35} />
            </div>
        }
    } else {
        return <Selling key='new' {...props} />
    }
}

export default withRouter(class extends React.Component<RouteComponentProps<{ page: string }>> {
    render() {
        return <RouterSwitch>
            <Route path={`/${this.props.match.params.page}/drafts/:draftId?`} component={SellingRouter} />
            <Route path={`/${this.props.match.params.page}/:postId?`} component={SellingRouter} />
        </RouterSwitch>
    }
})