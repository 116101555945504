import * as React from 'react'
import { IC_CREATE, IC_DELETE } from '../../assets';
import Draggable, { ControlPosition, DraggableData } from 'react-draggable';
import { getImageOrientation, rotateImg, RemoteImage, isRemoteFile } from '../../utils/helper';
import VideoThumbnail from 'react-video-thumbnail';

import { t } from 'i18next';

const SIZE = 84
const MARGIN = 8
const GRID_X = (SIZE + MARGIN)

type ModFindImageGridImage = ModFindImage | RemoteImage

export interface ModFindImage {
    file: File,
    url: string,
    type: string,
    orientation: number,
    position: number
}

export interface ModFindImageGridProps {
    onImagesChanged: (images: ModFindImageGridImage[]) => void
    images: ModFindImageGridImage[]
    disabled?: boolean
}

export interface ModFindImageGridState {
    images: ModFindImageGridImage[]
}

export default class ModFindImageGrid extends React.Component<ModFindImageGridProps, ModFindImageGridState> {

    constructor(props: ModFindImageGridProps) {
        super(props);
        this.state = {
            images: props.images,
        }
        this.selectImage = this.selectImage.bind(this)
        this.onDrag = this.onDrag.bind(this)
    }

    addImageFromFile(file: File) {
        const orientation = new Promise<number>(
            function (resolve) {
                getImageOrientation(file, function (orientation: number) {
                    resolve(orientation)
                })
            }
        )
        orientation.then((fileOrientation) => {
            const images = this.state.images
            const url = URL.createObjectURL(file)
            const image: ModFindImage = {
                file: file,
                url,
                type: file.type.split('/')[0],
                orientation: fileOrientation,
                position: images.length
            }
            images.push(image)
            this.setState({ images }, () => {
                this.props.onImagesChanged(this.state.images)
            })
        })
    }

    selectImage(e: React.ChangeEvent<HTMLInputElement> | React.DragEvent<HTMLDivElement>) {
        e.preventDefault()
        let files: FileList | null
        if ('files' in e.target) {
            files = e.target.files
        } else {
            files = (e as React.DragEvent<HTMLDivElement>).dataTransfer.files
        }
        if (files) {
            for (let index = 0; index < files.length; index++) {
                const file = files.item(index)!;
                this.addImageFromFile(file)
            }
        }
    }

    private deleteImage(index: number) {
        let images = this.state.images
        let pos = images[index].position
        images.forEach((image, index) => {
            if (image.position > pos) {
                image.position -= 1
            }
        })
        images.splice(index, 1)
        this.setState({ images }, () => {
            this.props.onImagesChanged(this.state.images)
            images.forEach((image, index) => {
                let ref = this.imageGridItem[index]
                if (ref) {
                    let newPos: ControlPosition = { x: image.position * GRID_X, y: 0 }
                    let currPos: ControlPosition = ref.getPosition()
                    if (currPos !== newPos) {
                        ref.setPosition(newPos)
                    }
                }
            })
        })
    }

    private onDrag(data: DraggableData, index: number, list: ModFindImageGridImage[]) {
        list.forEach((image, index) => {
            let ref = this.imageGridItem[index]
            if (ref) {
                let pos = ref.getPosition()
                if (pos && pos.x === data.x) {
                    ref.setPosition({ x: data.lastX, y: 0 })
                }
            }
        })
        let currRef = this.imageGridItem[index]
        if (currRef) {
            currRef.setPosition({ x: data.x, y: 0 })
        }

    }

    private onStop(list: ModFindImageGridImage[]) {
        let images = this.state.images
        images.forEach((image, index) => {
            let ref = this.imageGridItem[index]
            if (ref) {
                let pos = ref.getPosition()
                if (pos) {
                    images[index].position = pos.x / GRID_X
                }
            }
        })
        this.setState({ images })
        this.props.onImagesChanged(images)
    }

    private fileInput: HTMLInputElement | null = null
    private imageGridItem: Array<ImageGridItem | null> = [];

    render() {
        const { images } = this.state
        return (
            <div className="dragndrop-container">
                <span className='image-upload-description'>{t("Messages.UPLOAD_IMAGES")}</span>
                {images.map((el, index, list) => {
                    return (
                        <ImageGridItem
                            key={`image_${index}`}
                            ref={(ref) => this.imageGridItem[index] = ref}
                            image={el}
                            index={index}
                            length={list.length}
                            onDrag={(data) => this.onDrag(data, index, list)}
                            onStop={() => this.onStop(list)}
                            onDeleteClick={() => this.deleteImage(index)}
                        />
                    )
                })}
                {images.length < 10 && <Draggable disabled={this.props.disabled}
                    position={{ x: images.length * GRID_X, y: 0 }}
                    onStart={() => false}
                >
                    <div
                        className="add-image"
                        onClick={() => {
                            this.fileInput && this.fileInput.click()
                        }}
                    >
                        <img src={IC_CREATE} alt={'add'} width={16} height={16} style={{ touchAction: 'none' }} />
                    </div>
                </Draggable>}

                <input disabled={this.props.disabled}
                    type="file"
                    style={{ display: 'none' }}
                    onChange={this.selectImage}
                    accept="image/png, image/gif, image/jpeg, video/*, video/mp4" multiple
                    ref={ele => (this.fileInput = ele)}
                />
            </div>

        )
    }
}

export interface ImageGridItemProps {
    image: ModFindImageGridImage
    index: number
    length: number
    onDrag: (data: DraggableData) => void
    onStop: () => void
    onDeleteClick: () => void
}

export interface ImageGridItemState {
    position: ControlPosition
}

export class ImageGridItem extends React.Component<ImageGridItemProps, ImageGridItemState> {

    constructor(props: ImageGridItemProps) {
        super(props)
        this.state = {
            position: { x: this.props.image.position * GRID_X, y: 0 }
        }
    }

    setPosition(position: ControlPosition) {
        this.setState({ position })
    }

    getPosition(): ControlPosition {
        return this.state.position
    }

    render() {
        const { index, image, length } = this.props
        let rightBound = (length - 1) * GRID_X

        const url = isRemoteFile(image.file) ? image.file.url : (image as ModFindImage).url
        const orientation = isRemoteFile(image.file) ? 0 : (image as ModFindImage).orientation
        return (
            <Draggable
                axis="x"
                position={this.state.position}
                grid={[GRID_X, 0]}
                bounds={{ top: 0, left: 0, right: rightBound, bottom: 0 }}
                onDrag={(_e, data) => this.props.onDrag(data)}
                onStop={() => this.props.onStop()}
            >
                <div style={{ cursor: 'pointer' }} className="drag-image" >
                    {(image.type === 'image' || isRemoteFile(image.file)) && <img src={url} alt={index.toString()} width={84} height={84} style={{ touchAction: 'none', objectFit: 'cover', transform: `rotate(${rotateImg(orientation)}deg)` }} />}
                    {(image.type === 'video' && !isRemoteFile(image.file)) && <VideoThumbnail videoUrl={url} />}
                    <img src={IC_DELETE} alt={'delete'} width={24} height={24} className="delete" onClick={this.props.onDeleteClick} />
                    {image.position === 0 && <div className="cover-box">
                        <span className="cover-text">{'COVER'}</span>
                    </div>}
                </div>
            </Draggable>
        )
    }

}